<template>
  <div>
    <div class="mypage_form">
      <div class="form_left">
        <div class="center_border"></div>
        <!-- 소속명 -->
        <p class="title">{{ $t("mypage.companyName") }}</p>
        <div class="input_box">
          <CInput
            class="mb-0"
            v-model="artistInfo.companyName"
            :disabled="isCorporation"
          />
        </div>
        
        <!-- 이름 -->
        <p class="title">{{ $t("mypage.name") }}</p>
        <div class="input_box">
          <CInput
            class="mb-0"
            v-model="memberInfo.name"
            :disabled="isCorporation"
          />
        </div>

        <!-- 사용자이름 (닉네임) -->
        <p class="title">{{ $t("mypage.nickname") }}&nbsp;<span class="text-danger font-lg">*</span></p>
        <div class="input_box">
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="$v.memberInfo.nickname.$model"
            :isValid="checkIfValid('nickname')"
            :invalidFeedback="$t('mypage.validMsgUsername')"
            :disabled="isCorporation"
          />
        </div>
      </div>

      <div class="form_right">
        <!-- 이메일계정 -->
        <p class="title">{{ $t("mypage.email") }}</p>
        <div class="input_box">
          <CInput
            class="mb-0"
            :value.sync="$v.memberInfo.email.$model"
            :disabled="isCorporation"
          />
        </div>
        <!-- 연락처 -->
        <p class="title">{{ $t("mypage.mobile") }}</p>
        <div class="input_box">
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="$v.memberInfo.mobile.$model"
            :isValid="checkIfValid('mobile')"
            :invalidFeedback="$t('mypage.validMsgMobile')"
            :disabled="memberInfo.identityVerify === true || isCorporation"
          >
          </CInput>
        </div>
        <!-- 위치 -->
        <p class="title">{{ $t("mypage.location") }}</p>
        <div class="input_box">
          <CInput
            class="mb-0"
            v-model="memberInfo.location"
            :disabled="isCorporation"
          />
        </div>

      </div>

      <div class="form_submit_btn">
        <p class="text-danger text-center">{{ errorMessage }}</p>
        <CButton
          type="submit"
          @click="onClickSave"
          :disabled="isCorporation"
        >
          {{ $t("mypage.save") }}
        </CButton>
      </div>
    </div>

    <!-- ETC ================================ -->
    <!-- 지갑 연결 팝업 -->
    <WalletPopup ref="walletPopupModal" @wallet-info="getWalletInfo" />

    <!-- 완료 팝업 -->
    <b-modal
      ref="save-message-modal2"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <b-img src="/img/threespace/logo/logo_3space_color.png" width="64"></b-img>
      <p class="my-5 font-lg">{{ completeMessage }}</p>
      <b-button
        variant="primary"
        size="lg"
        squared
        class="mt-3 px-5"
        @click="hideSaveMessageModal"
      >
        <span class="text-white">{{ $t("button.confirm") }}</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  helpers,
  maxLength,
  email
} from "vuelidate/lib/validators";
import { createNamespacedHelpers, mapState } from "vuex";
import WalletPopup from "@/views/threespace/WalletPopup";
const profileHelper = createNamespacedHelpers("profile");
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "MyPageUpdateProfile",
  components: {
    WalletPopup,
  },
  data() {
    return {
      instagram: "",
      twitter: "",
      homepage: "",
      selectedTab: 0,
      tabs: [
        { index: 0, name: this.$t("mypage.profile") },
        { index: 1, name: this.$t("mypage.collection") },
        { index: 2, name: this.$t("mypage.payment") }
      ],
      // (Tab 1) 프로필
      errorMessage: null,
      profileImage: [],
      clipboardCopied: false,
      listOrderSelected: "LATEST",
      listOrderOptions: [
        { text: "최신순", value: "LATEST" },
        { text: "낮은가격순", value: "LOW_PRICE" },
        { text: "높은가격순", value: "HIGH_PRICE" }
      ],
      completeMessage: null,
      acceptDeleteMember: false,
      acceptErrorMessage: null,
      acceptCheckTotal: false,
      selectedWallet: null,
      walletAddress: {
        klaytn: null,
        ethereum: null
      }
    };
  },
  computed: {
    ...profileHelper.mapState(["memberInfo"]),
    ...authHelper.mapState(["me", "isCorporation"]),
    ...mapState("gallery", ["artistInfo"]),
    isValid() {
      return !this.$v.memberInfo.$invalid;
    },
    isDirty() {
      return this.$v.memberInfo.$anyDirty;
    },
    _instagram: {
      get: function() {
        if (this.memberInfo.instagram != undefined && this.memberInfo.instagram !== "") {
          return "instagram.com/" + this.memberInfo.instagram
        } else {
          return "instagram.com/"
        }
      },
      set: function(newVal) {
        let strArr = newVal.split("/")
        if (strArr[strArr.length - 1] !== "") {
          this.instagram = strArr[strArr.length - 1];
        }
      }
    },
    _twitter: {
      get: function() {
        if (this.memberInfo.twitter != undefined && this.memberInfo.twitter !== "") {
          return "twitter.com/" + this.memberInfo.twitter
        } else {
          return "twitter.com/"
        }
      },
      set: function(newVal) {
        let strArr = newVal.split("/")
        if (strArr[strArr.length - 1] !== "") {
          this.twitter = strArr[strArr.length - 1];
        }
      }
    },
    _website: {
      get: function() {
        if (this.memberInfo.homepage != undefined && this.memberInfo.homepage !== "") {
          return this.memberInfo.homepage
        } else {
          return ""
        }
      },
      set: function(newVal) {
        if (newVal !== "") {
          this.homepage = newVal;
        }
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    memberInfo: {
      nickname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
        strongPass: helpers.regex("strongPass", /^[a-zA-Z0-9._-]*$/)
      },
      mobile: {
        minLength: minLength(10),
        maxLength: maxLength(12),
        numbers: helpers.regex("numbers", /^[0-9]*$/)
      },
      email: { email },
      //acceptTermsService: { required, mustAccept: val => val },
      //acceptPrivacyPolicy: { required, mustAccept: val => val }
    }
  },
  mounted() {
    this.requestMemberItem();
  },
  methods: {
    ...profileHelper.mapActions([
      "getProfile",
      "updateProfile",
      "deleteMember",
      "getAuthRequestData"
    ]),
    ...authHelper.mapActions(["logout"]),
    // (Tab 1) 프로필 >>>>>
    requestMemberItem() {
      if (this.me.idx && this.me.idx !== "0") {
        this.getProfile(this.me.idx)
          .then(() => {
            //this.$log.log(this.memberInfo);//TODO
            /*
            if (this.memberInfo.mobile === undefined || this.memberInfo.mobile === "") {
              this.memberInfo.mobile = null;
            }
            if (this.memberInfo.email === undefined || this.memberInfo.email === "") {
              this.memberInfo.email = null;
            }*/
            // 주소 변경 > 중복 체크후 복원용
            this.walletAddress.klaytn = this.memberInfo.klaytnAddr || null;
            this.walletAddress.ethereum = this.memberInfo.ethereumAddr || null;
          })
          .catch(response => {
            this.$log.error(response);
            this.$router.push("/error/500");
          });
      }
    },
    onClickSave() {
      this.errorMessage = "";

      if (this.isValid) {
        // 사용자 정보 저장
        if (!!this.me.idx) {
          this.memberInfo.instagram = this.instagram;
          this.memberInfo.twitter = this.twitter;
          this.memberInfo.homepage = this.homepage;
          this.updateProfile(this.memberInfo)
            .then(() => {
              this.completeMessage = this.$t("mypage.updatedProfile");
              this.$refs["save-message-modal2"].show();
              this.$v.$reset();
            })
            .catch(response => {
              this.$log.error(response);
              if (response.data.error === "BAD_REQUESTED") {
                if (response.data.resource === "EXISTS_NICKNAME") {
                  this.errorMessage = this.$t("validation.exist.username");
                } else if (response.data.resource === "EXISTS_EMAIL") {
                  this.errorMessage = this.$t("validation.exist.email");
                } else if (response.data.resource === "EXISTS_KLAYTN_ADDR") {
                  this.errorMessage = this.$t("validation.exist.klaytnWalletAddr");
                  this.memberInfo.klaytnAddr = this.walletAddress.klaytn;
                } else if (response.data.resource === "EXISTS_ETHEREUM_ADDR") {
                  this.errorMessage = this.$t("validation.exist.ethereumWalletAddr");
                  this.memberInfo.ethereumAddr = this.walletAddress.ethereum;
                } else if (response.data.resource === "EXISTS_WALLET_ADDR") {
                  this.memberInfo.walletAddr = null;
                  this.memberInfo.walletNetwork = null;
                  this.errorMessage = this.$t("validation.exist.walletAddr");
                }
              } else {
                this.errorMessage = this.$t("mypage.errorProfile");
              }
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    showWalletPopupModal(networkType) {
      this.selectedWallet = networkType;
      this.$refs["walletPopupModal"].showChangeWallet(networkType);
    },
    getWalletInfo(data) {
      if (this.memberInfo.snsType !== undefined && this.memberInfo.snsType !== "WALLET") {
        if (this.selectedWallet !== undefined) {
          if (this.selectedWallet === "Ethereum") {
            if (process.env.NODE_ENV === "production" && data.chainId.toString() !== "1") {
              let msg = this.$t("validation.wallet.ethereumMainnet");
              alert(msg);
              return;
            }
            this.memberInfo.ethereumAddr = data.addr;
            this.memberInfo.ethereumProvider = data.walletProvider;
            this.$forceUpdate();
          } else if (this.selectedWallet === "Klaytn") {
            this.memberInfo.klaytnAddr = data.addr;
            this.memberInfo.klaytnProvider = data.walletProvider;
            this.$forceUpdate();
          }
        }
      }
      //this.memberInfo.walletAddr = data.addr;
      //this.memberInfo.walletNetwork = data.network;
    },
    checkIfValid(fieldName) {
      const field = this.$v.memberInfo[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    hideSaveMessageModal() {
      this.$refs["save-message-modal2"].hide();
    },
    onCheckAccept() {
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        this.acceptErrorMessage = "";
      }
    },
    onClickToLeave() {
      this.completeMessage = "";
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        if (this.me.idx && this.me.idx !== "0") {
          this.deleteMember({ idxMember: this.me.idx })
            .then(() => {
              this.logout().then(() => {
                this.completeMessage = this.$t("mypage.memberLeave.complete");
                this.$refs["delete-message-modal"].show();
                this.$refs["delete-confirm-modal"].hide();
              });
            })
            .catch(error => {
              this.$log.error(error);
              this.completeMessage = this.$t("mypage.memberLeave.error");
              this.$refs["delete-message-modal"].show();
              this.$refs["delete-confirm-modal"].hide();
            });
        }
      }
    },
    showIdentityVerify() {

      this.getAuthRequestData()
        .then(data => {
          let niceAuthForm = document.niceAuthForm;
          let url = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";

          window.app = this;
          window.open("" ,"niceAuthForm", "toolbar=no, width=480, height=600, directories=no, status=no, scrollorbars=no, resizable=no");
          niceAuthForm.action = url;
          niceAuthForm.method = "post";
          niceAuthForm.target = "niceAuthForm";
          niceAuthForm.token_version_id.value = data.tokenVersionId;
          niceAuthForm.enc_data.value = data.encData;
          niceAuthForm.integrity_value.value = data.integrityValue;
          niceAuthForm.submit();
        })
        .catch(error => {
          this.$log.error(error);
        });
    },
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  }
};
</script>
