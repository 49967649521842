<template>
  <div>
    <div class="mypage_form mypage_artist">
      <div class="artist_info_box">
        <p class="title">{{ $t("mypage.artistInfoCon.artistInfo") }}</p>
        <p class="content">
          {{ $t("mypage.artistInfoCon.artistInfo1") }}  <br />
          {{ $t("mypage.artistInfoCon.artistInfo2") }}  <br />
          {{ $t("mypage.artistInfoCon.artistInfo3") }}
        </p>
      </div>
      <div class="overflow-hidden mb-5 pb-5">
        <div class="form_left">
          <div class="center_border"></div>
          <!-- 작가명 -->
          <p class="title">{{ $t("mypage.artistName") }}</p>
          <div class="input_group_box">
            <p class="group_title">{{ $t("mypage.eng") }}<span>*</span></p>
            <b-form-input
              type="text"
              v-model="artistInfo.artistNameEn" 
              :placeholder="$t('mypage.required')"
              :disabled="isCorporation"
            />
            <p class="group_title">{{ $t("mypage.kor") }}</p>
            <b-form-input
              type="text"
              v-model="artistInfo.artistName"
              :disabled="isCorporation"
            />
          </div>
          <!-- 작가소개 -->
          <p class="title">{{ $t("mypage.artistAbout") }}</p>
          <div class="input_group_box">
            <p class="group_title">{{ $t("mypage.eng") }}<span>*</span></p>
            <b-form-textarea
              v-model="artistInfo.introduceEn"
              :placeholder="$t('mypage.required')"
              :disabled="isCorporation"
            ></b-form-textarea>
            <p class="group_title">{{ $t("mypage.kor") }}</p>
            <b-form-textarea
              v-model="artistInfo.introduce"
              :disabled="isCorporation"
            ></b-form-textarea>
          </div>
        </div>
        <div class="form_right">
          <!-- 작가이력 -->
          <p class="title">{{ $t("mypage.artistBio") }}</p>
          <div class="input_group_box">
            <p class="group_title">{{ $t("mypage.eng") }}</p>
            <b-form-textarea
              v-model="artistInfo.careerEn"
              :disabled="isCorporation"
            ></b-form-textarea>
            <p class="group_title">{{ $t("mypage.kor") }}</p>
            <b-form-textarea
              v-model="artistInfo.career"
              :disabled="isCorporation"
            ></b-form-textarea>
          </div>
          <div class="form_submit_btn form_artist_info_submit_btn">
            <p class="text-danger text-center">{{ errorMsg }}</p>
            <CButton
              type="submit"
              @click="onClickSaveArtist('default')"
              :disabled="isCorporation"
            >
              {{ $t("mypage.save") }}
            </CButton>
          </div>
        </div>
      </div>
      <template v-if="!isCorporation">
        <div class="artist_info_box">
          <p class="title">{{ $t("mypage.artistInfoCon.artistSettle") }}</p>
          <div class="content">
            <div class="info_list">
              <div class="dot"></div>
              {{ $t("mypage.artistInfoCon.artistSettle1") }} <br />
              - {{ $t("mypage.artistInfoCon.artistSettle2") }}<br />
              - {{ $t("mypage.artistInfoCon.artistSettle3") }}        
            </div>
            <div class="info_list">
              <div class="dot"></div>
              {{ $t("mypage.artistInfoCon.artistSettle4") }}<br />
              - {{ $t("mypage.artistInfoCon.artistSettle5") }}<br />
              - {{ $t("mypage.artistInfoCon.artistSettle6") }}<br />
              - {{ $t("mypage.artistInfoCon.artistSettle7") }}
            </div>
          </div>
        </div>
        <!-- <div class="form_wide">
          <div class="input_wide_box select_group">
            <p class="title">
              {{ $t("mypage.artistInfoCon.bankAccount") }}
            </p>
            <b-form-select
              :options="bankCodeOptions"
              v-model="bankSelected"
              disabled
            ></b-form-select>
            <b-form-input
              type="text"
              v-model="artistInfo.bankAccount"
              disabled
            />
          </div>
          <div class="input_wide_box">
            <p class="title">{{ $t("mypage.artistInfoCon.klaytnAddress") }}</p>
            <b-form-input type="text" v-model="artistInfo.klaytnAddr" disabled />
          </div>
          <div class="input_wide_box">
            <p class="title">{{ $t("mypage.artistInfoCon.ethereumAddress") }}</p>
            <b-form-input type="text" v-model="artistInfo.ethereumAddr" disabled />
          </div>
        </div>
        <div class="form_submit_btn form_artist_submit_btn">
          <CButton
            type="submit"
            @click="onClickSaveArtist('settle')"
            :disabled="isCorporation"
          >
            {{ $t("mypage.save") }}
          </CButton>
        </div> -->
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "MyPageUpdateArtist",
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      artistInfo: {},
      bankSelected: "",
      errorMsg: "",
      bankCodeOptions: [
        { value: "", text: "선택" },
        { value: "003", label: "기업은행" },
        { value: "004", label: "국민은행" },
        { value: "020", label: "우리은행" },
        { value: "081", label: "하나은행" },
        { value: "088", label: "신한은행" },
        { value: "027", label: "씨티은행" },
        { value: "011", label: "NH농협은행" },
        { value: "090", label: "카카오뱅크" },
        { value: "092", label: "토스뱅크" },
      ],
    };
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me", "isCorporation"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.requestArtistInfo();
    })
  },
  methods: {
    ...mapActions("profile", [
      "updateProfileArtist",
    ]),
    ...mapActions("gallery", ["getGalleryArtist"]),
    requestArtistInfo() {
      if (this.isArtist) {
        this.getGalleryArtist(this.memberInfo.idxArtist).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.artistInfo = data;
          }
        });
      }
    },
    onClickSaveArtist(type) {
      this.errorMsg = "";
      this.artistInfo.saveType = type; // default: 기본정보만 저장, settle: 정산정보만 저장
      this.artistInfo.bankCode = this.bankSelected;
      if (this.artistInfo.artistNameEn === undefined || this.artistInfo.artistNameEn === null || this.artistInfo.artistNameEn === "") {
        this.errorMsg = this.$t("mypage.requiredArtistNameEn");
        return;
      }
      if (this.artistInfo.introduceEn === undefined || this.artistInfo.introduceEn === null || this.artistInfo.introduceEn === "") {
        this.errorMsg = this.$t("mypage.requiredIntroduceEn");
        return;
      }
      this.updateProfileArtist(this.artistInfo).then(() => {
        // alert(this.$t("mypage.updatedProfile"));
        this.$router.push({name: "MyPageDetail", params: {updateComplete: true}});
      }).catch(() => {
        alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
      });
    },
  }
};
</script>
