<template>
  <div>
    <div class="mypage_form mypage_settings">
      <div class="form_left">
        <div class="center_border"></div>
        <!-- 컬렉션 비공개 설정 -->
        <p class="title">{{ $t("mypage.collectionCon.title") }}</p>
        <div class="input_check_box">
          <b-form-group>
            <b-form-checkbox
              v-model="closeCollection"
              switch
            >
              <p>{{ $t("mypage.collectionCon.close") }}</p>
              <p>{{ $t("mypage.collectionCon.closeCon") }}</p>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="openCollection"
              switch
            >
              <p>{{ $t("mypage.collectionCon.open") }}</p>
              <p>{{ $t("mypage.collectionCon.openCon") }} </p>
            </b-form-checkbox>
          </b-form-group>
          <b-button
            class="pl-4 font-weight-bold d-block"
            variant="link"
            :disabled="memberInfo.collection === undefined || !memberInfo.collection"
            @click="showSelectCollectionModal"
          >
            {{ $t("mypage.collectionCon.setting") }}
          </b-button>
        </div>
      </div>
      <div class="form_right" v-if="!isArtist">
        <!-- @TODO 이메일 알림 추가 안하는지?  -->
        <template v-if="false">
          <p class="title">이메일 알림</p>
          <div class="input_check_box">
            <b-form-group>
              <b-form-checkbox v-model="memberInfo.noticeArtwork">
                <p>새 작품 알림</p>
                <p>새로운 NFT작품이나 컬렉션에 대한 알림을 수신할 수 있습니다. </p>
              </b-form-checkbox>
              <b-form-checkbox v-model="memberInfo.noticeExhibit">
                <p>오프라인 전시</p>
                <p>새로운 오프라인 전시에 관한 알림을 수신 할 수 있습니다. </p>
              </b-form-checkbox>
            </b-form-group>
          </div>
          <p class="text-danger" v-if="rightErrorMassage !== ''">
            {{ rightErrorMassage }}
          </p>
          <div class="form_submit_btn form_settings_submit_btn">
            <CButton
              type="submit"
              @click="onClickSaveSattings"
            >
              {{ $t("mypage.save") }}
            </CButton>
          </div>
        </template>
        <div class="form_member_leave">
          <!-- 계정 탈퇴 -->
          <p class="title">{{ $t("mypage.leave.accountWithdrawal") }}</p>
          <p class="leave_content" v-html="$t('mypage.leave.accountWithdrawalWarning')">
          </p>
          <b-button variant="link" class="pl-0" @click="onClickToLeaveConfirm">
            <span>
              {{ $t("mypage.withdrawal") }}
            </span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- ETC ================================ -->
    <!-- 탈퇴하기 팝업 -->
    <b-modal
      ref="delete-confirm-modal"
      centered
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      header-class="border-bottom-0 text-black"
      body-class="text-center text-black pt-0 pb-3 px-5"
    >
      <div class="delete_modal_content">
        <b-img src="/img/threespace/logo/logo_3space_color.png" width="64"></b-img>
        <p class="big_content" v-html="replaceNewLine($t('mypage.memberLeave.confirmMsg'))"></p>
        <p class="small_content">
          1. {{ $t("mypage.memberLeave.info1") }}<br />
          2. {{ $t("mypage.memberLeave.info2") }}
        </p>
        <CInputCheckbox
          :label="$t('mypage.memberLeave.agree')"
          :checked.sync="acceptDeleteMember"
          custom
          @change="onCheckAccept"
        />
        <p class="text-danger">{{ acceptErrorMessage }}</p>
        <b-button @click="onClickToLeave">
          {{ $t("button.completeAccountClose") }}
        </b-button>
      </div>
    </b-modal>

    <!-- 탈퇴하기 완료 팝업 -->
    <b-modal
      ref="delete-message-modal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center text-black py-5"
    >
      <div class="delete_modal_content">
        <b-img src="/img/threespace/logo/logo_3space_color.png" width="64"></b-img>
        <p class="small_content">{{ completeMessage }}</p>
        <b-button @click="hideDeleteMessageModal">
          {{ $t("button.confirm") }}
        </b-button>
      </div>
    </b-modal>

    <MyPageCollectionVisibleModal ref="myPageCollectionVisibleModal" />
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MyPageCollectionVisibleModal from './MyPageCollectionVisibleModal.vue';

export default {
  components: { 
    MyPageCollectionVisibleModal,
   },
  name: "MyPageUpdateSettings",
  props: {
    isArtist: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      completeMessage: "",
      acceptDeleteMember: false,
      acceptErrorMessage: null,
      rightErrorMassage: "",
      isBusy: false,
    };
  },
  computed: {
    ...mapState("profile", ["memberInfo"]),
    ...mapState("auth", ["me"]),
    closeCollection: {
      get: function() {
        return !this.memberInfo.collection
      },
      set: function(newVal) {
        this.isBusy = true;
        this.memberInfo.collection = !newVal
        if (newVal) {
          this.updateProfileSetting(this.memberInfo).then(() => {
            this.isBusy = false;
          }).catch(() => this.isBusy = false)
        }
      }
    },
    openCollection: {
      get: function() {
        return this.memberInfo.collection
      },
      set: function(newVal) {
        this.memberInfo.collection = newVal
        if (newVal) {
          this.updateProfileSetting(this.memberInfo).then(() => {
            this.isBusy = false;
          }).catch(() => this.isBusy = false)
        }
      }
    },
  },
  methods: {
    ...mapActions("profile", [
      "updateProfileSetting",
      "deleteMember",
      "getAuthRequestData"
    ]),
    ...mapActions("collection", [
      "getCollectionsAll"
    ]),
    ...mapActions("auth", [
      "logout",
    ]),
    showSelectCollectionModal() {
      if (this.me.idx != undefined) {
        this.getCollectionsAll({
          keywordIdxMember: this.me.idx,
          keywordStaked: false
        }).then(() => {
          this.$refs["myPageCollectionVisibleModal"].show();
        })
      }
    },
    onClickSaveSattings() {
      this.updateProfileSetting(this.memberInfo).then(() => {
        alert("성공적으로 저장되었습니다.")
      }).catch(() => {
        alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
      });
      // this.rightErrorMassage = "";
      // if (this.memberInfo.email !== undefined && this.memberInfo.email !== "") {
      //   this.updateProfileSetting(this.memberInfo).then(() => {
      //     alert("성공적으로 저장되었습니다.")
      //   }).catch(() => {
      //     alert("요청에 실패하였습니다. 관리자에게 문의하세요.");
      //   });
      // } else {
      //   this.rightErrorMassage = "프로필설정에서 알림을 받을 이메일을 설정해주세요."
      // }
    },
    onClickToLeaveConfirm() {
      if (this.me.idx && this.me.idx !== "0") {
        this.$refs["delete-confirm-modal"].show();
      }
    },
    onClickToLeave() {
      this.completeMessage = "";
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        if (this.me.idx && this.me.idx !== "0") {
          this.deleteMember({ idxMember: this.me.idx })
            .then(() => {
              this.logout().then(() => {
                this.completeMessage = this.$t("mypage.memberLeave.complete");
                this.$refs["delete-message-modal"].show();
                this.$refs["delete-confirm-modal"].hide();
              });
            })
            .catch(error => {
              this.$log.error(error);
              this.completeMessage = this.$t("mypage.memberLeave.error");
              this.$refs["delete-message-modal"].show();
              this.$refs["delete-confirm-modal"].hide();
            });
        }
      }
    },
    hideDeleteMessageModal() {
      this.$refs["delete-message-modal"].hide();
      this.$router.push({ name: "Drops" }).catch(() => {});
    },
    onCheckAccept() {
      if (!this.acceptDeleteMember) {
        this.acceptErrorMessage = this.$t("validation.required");
      } else {
        this.acceptErrorMessage = "";
      }
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  }
};
</script>
